// api link
export const API_LINK = process.env.REACT_APP_BASE_API_URL;
// export const API_LINK = "https://hklogistics.kr/api/";
// export const API_LINK = "https://hklogistics.kr/api/";
// export const API_LINK = "http://baechaking-stg-1831241252.ap-northeast-2.elb.amazonaws.com/api/";
//export const API_LINK = "http://3.35.9.218:3000/api/";
// export const API_LINK = "http://43.200.169.255:3000/api/";

// save local storage
export const ACCESS_TOKEN = "access_token_admin";
export const REFRESH_TOKEN = "refresh_token_admin";
export const REGISTER_NOTIFICATION = "register_notification_admin";
export const NOTIFY_ENDPOINT = "notify_endpoint";
export const NOTIFY_AUTH = "notify_auth";
export const NOTIFY_P256DH = "notify_p256dh";

export const Colors = {
  black: "#000000",
};

// status ORDERS
export const STATUS_WAITING_APPROVAL = "WAITING_APPROVAL";
export const STATUS_WAITING_MATCHING = "WAITING_MATCHING";
export const STATUS_WAITING_LOADING = "WAITING_LOADING";
export const STATUS_DELIVERING = "DELIVERING";
export const STATUS_WAITING_COMPLETION = "WAITING_COMPLETION";
export const STATUS_WAITING_PAYMENT = "WAITING_PAYMENT";
export const STATUS_DELIVERED = "DELIVERED";
export const STATUS_ARRIVING = "ARRIVING";
export const STATUS_CANCEL = "CANCEL";
export const STATUS_DONE = "DONE";
export const STATUS_WAITING_COMEBACK = "WAITING_COMEBACK";

// search
export const SEARCH_ORDER = "order=desc&orderBy=createdAt";
export const PROVIDER = "PROVIDER";
export const PAYBACK_RATE = 1 / 100;
export const GRAPH_RATE = 1000000;
export const PAYMENT_TABLE_LIMIT = 1000000000;
export const PER_PAGE = 10;
export const PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZE = 20;

// date time format
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_DOT = "YYYY.MM.DD";
export const TIME_FORMAT = "hh:mm a";
export const DATE_TIME_FORMAT = "YYYY-MM-DD hh:mm a";
export const DATE_TIME_FORMAT_DOT = "YYYY.MM.DD hh:mm a";
export const DATE_TIME_FORMAT_24DOT = "YY.MM.DD HH:mm A";
export const MONTH_FORMAT = "YYYY-MM";
export const MONTH_FORMAT_DOT = "YYYY.MM";
export const YEAR_FORMAT = "YYYY";
export const DATE_WITH_TIMEZONE_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";

let dateString = new Date().toString();
const dateStringArr = dateString.split(/[" "]/);
export const GMT = dateStringArr[5].slice(3, 8);
export const GMT_KO = "%2B0900"; //+0900

export const vehicleTonnageOptions = [
  "1톤",
  "다마스",
  "라보",
  "1.4톤",
  "2.5톤",
  "3.5톤",
  "5톤",
  "5톤 축",
  "11톤",
  "11톤 축",
  "18톤",
  "25톤",
] as const;
export const vehicleTypeOptions = ["카고", "윙", "탑", "냉장", "냉동", "무진동", "항온항습", "리프트"];
export const TINYMCE_KEY = "oatf44uxzao6a1zsydheocpohwh8huqr0aj8o7ialslxhkh3";
export const RouterType = {
  RECOMMEND: "RECOMMEND",
  TIME: "TIME",
  DISTANCE: "DISTANCE",
};

export const MIN_DISCOUNT_FREIGHT_COST = 20000;

export const KAKAO_REST_API_KEY = "11fec52b8ebe8ccc93c1a84d52b10b7b";

