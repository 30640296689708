import { Button, Space } from "antd";
import { SetStateAction, useEffect, useState } from "react";
import cx from "classnames";
import starIcon from "@/assets/images/star.svg";
import starFavourite from "@/assets/images/starFavourite.svg";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "@/components/customModal/modalSlice";
import Step1 from "./components/Step1";
import { RegisterDispatchProvider } from "@/context/RegisterContext";
import StepAnother from "./components/StepAnother";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useGetPartnerListQuery, useGetVehicleDetailQuery } from "./VehicleRegisApi";
import { useForm } from "antd/lib/form/Form";
import numeral from "numeral";
import moment from "moment";
import { RouterKakao } from "@/utils/types/kakao";
import { useCallbackPrompt } from "@/hooks/useCallbackPrompt";
import { EOrderType } from "@/utils/types/order";
import { getUserLogin } from "@/store/selector/RootSelector";
import { MIN_DISCOUNT_FREIGHT_COST } from "@/utils/constants";
import { formatPhoneNumber } from "@/utils/utils";

export default function DispatchModify() {
    const location: any = useLocation();
    // const userInfo = useSelector(getUserInfo);
    const queryString = window.location.search;
    const navigate = useNavigate();
    const loginStatus = useSelector(getUserLogin);
    const [showFavorite, setFavorite] = useState(true);
    const [step, setStep] = useState(1);
    const [favoriteDispatch, setfavoriteDispatch] = useState<any>({});
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [method, setMethod] = useState<string | "one-way" | "two-way">("");
    const [form] = useForm();
    const urlParams = new URLSearchParams(queryString);
    const curMethod: any = urlParams.get("method");
    const [isFavouritePicker, setIsFavouritePicker] = useState(false);
    // const isNormalMember = userInfo?.type === "NORMAL";
    const { data: dataDetail } = useGetVehicleDetailQuery(
        {
            id,
        },
        {
            skip: !id,
            refetchOnMountOrArgChange: true
        },
    );

    // const { data: partnerList } = useGetPartnerListQuery({
    //     isFetchDeleted: false,
    // });

    // console.log(partnerList);

    const dispatch = useDispatch();

    useEffect(() => {
        if (id) setStep(1);
    }, [id]);

    const [isBlocking, setIsBlocking] = useState(false);
    if (useCallbackPrompt) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useCallbackPrompt(isBlocking);
    }

    useEffect(() => {
        // temporary fixed bug/bk-606
        if (!loginStatus) {
            navigate("/auth");
        }
    }, [loginStatus]);

    // useEffect(() => {
    //     form.setFieldsValue({
    //         isFastShipping: false,
    //         freightCost: numeral(MIN_DISCOUNT_FREIGHT_COST).format("0,00"),
    //         fastShipping: numeral(MIN_DISCOUNT_FREIGHT_COST + 30000).format("0,00"),
    //         estimatedDistance: 0,
    //         isCombineGoods: "unregister",
    //         info: itemInfoDefault,
    //         packingMethod: parkingMethodDefault,
    //         tonnage: tonnaDefault,
    //         vehicleType: vehicleDefault,
    //         startDate: moment(),
    //         endDate: moment(),
    //     });

    //     return () => { };
    // }, []);

    useEffect(() => {
        let initData = dataDetail;
        console.log("load dispatch", initData);

        /* --------------------------------- 상차지 나열 --------------------------------- */
        const startRoutersDefault: RouterKakao[] =
            method === "two-way"
                ? [
                    {
                        coorX: initData?.loadingPlaceCoordX,
                        coorY: initData?.loadingPlaceCoordY,
                        lng: initData?.loadingPlaceX,
                        lat: initData?.loadingPlaceY,
                        fullAddress: initData?.loadingPlace,
                        detailAddress: initData?.detailLoadingPlace,
                        lotNumAddress: initData?.loadingLotNumAddress
                    },
                ]
                : [
                    {
                        coorX: initData?.loadingPlaceCoordX,
                        coorY: initData?.loadingPlaceCoordY,
                        lng: initData?.loadingPlaceX,
                        lat: initData?.loadingPlaceY,
                        fullAddress: initData?.loadingPlace,
                        detailAddress: initData?.detailLoadingPlace,
                        lotNumAddress: initData?.loadingLotNumAddress
                    },
                    ...(initData?.transitPlaces || []),
                ];

        /* --------------------------------- 하차지 나열 --------------------------------- */
        const endRoutersDefault: RouterKakao[] =
            method === "two-way"
                ? [
                    {
                        coorX: initData?.receiptPlaceCoordX,
                        coorY: initData?.receiptPlaceCoordY,
                        lng: initData?.receiptPlaceX,
                        lat: initData?.receiptPlaceY,
                        fullAddress: initData?.receiptPlace,
                        detailAddress: initData?.detailReceiptPlace,
                        lotNumAddress: initData?.receiptLotNumAddress
                    },
                ]
                : [
                    {
                        coorX: initData?.receiptPlaceCoordX,
                        coorY: initData?.receiptPlaceCoordY,
                        lng: initData?.receiptPlaceX,
                        lat: initData?.receiptPlaceY,
                        fullAddress: initData?.receiptPlace,
                        detailAddress: initData?.detailReceiptPlace,
                        lotNumAddress: initData?.receiptLotNumAddress
                    },
                    ...(initData?.additionalEndpoints || []),
                ];

        const initFreightCost = Math.max(numeral(initData?.freightCost).value() || 0, MIN_DISCOUNT_FREIGHT_COST);
        let fastShipping = initData?.isFastShipping
            ? initFreightCost
            : initFreightCost < 300000
                ? initFreightCost + 30000
                : initFreightCost + 50000;

        let currentDispatch = {
            ...initData,
            type: initData?.type,
            info: initData?.info,
            quantity: initData?.quantity,
            packingMethod: initData?.packingMethod,
            fax: initData?.fax,
            tonnage: initData?.tonnage,
            vehicleType: initData?.vehicleType,
            isCombineGoods: initData?.isCombineGoods ? "registered" : "unregister",
            combineGoods: initData?.combineGoods,
            requirement: initData?.requirement,
            isFastShipping: initData?.isFastShipping,
            docUrls: initData?.docUrls,
            contactSender: initData?.contactSender,
            contactReceiver: formatPhoneNumber(initData?.contactReceiver),
            routeUrl: initData?.routeUrl,
            orderType: initData?.orderType,
            freightCost: numeral(initFreightCost).format("0,00"),
            fastShipping: numeral(fastShipping > 0 ? fastShipping : initFreightCost).format("0,00"),
            startDate: moment(initData?.expectedStartDate),
            startTime: moment(initData?.expectedStartDate),
            endDate: moment(initData?.expectedEndDate),
            endTime: moment(initData?.expectedEndDate),
            isPickUpImmediately: initData?.isPickUpImmediately,
            isDropDownImmediately: initData?.isDropDownImmediately,
            estimatedDistance: numeral(initData?.estimatedDistance).format("0,00"),
            addresses_step_2: startRoutersDefault,
            addresses_step_3: endRoutersDefault,
            isCash: initData?.isCash
        };

        // if (initData?.providerPartnerId) {
        //     currentDispatch["providerPartnerId"] =
        //         initData?.providerPartnerId && partnerList?.filter((p: { id: any; }) => p?.id === initData?.providerPartnerId)?.length > 0
        //             ? initData?.providerPartnerId
        //             : "해당 없음";
        // }

        let currentMT = id && initData?.orderType === EOrderType.ROUND_TRIP ? "two-way" : "one-way";

        setMethod(curMethod ? curMethod : currentMT);

        form.setFieldsValue({ ...currentDispatch });
    }, [JSON.stringify(dataDetail), JSON.stringify(favoriteDispatch), isFavouritePicker, method]);

    /* ----------------------------- 차종 리스트 추전 팝업 열기 ---------------------------- */
    const handleVehicleTip = () => {
        dispatch(
            openModal({
                template: "vehicle-tip",
                width: 996,
                actionConfirm: (e: any) => { },
            }),
        );
    };

    /* ----------------------------- 선호 노선 리스트 팝업 열기 ---------------------------- */
    const handleFavoriteRouter = () => {
        dispatch(
            openModal({
                template: "favorite-router",
                width: 555,
                actionConfirm: (selectedVehicle: any) => {
                    console.log(selectedVehicle);

                    setIsFavouritePicker(!isFavouritePicker);
                    setFavorite(true);
                    setfavoriteDispatch(selectedVehicle);
                },
                data: {
                    isFavourite: true,
                    method,
                },
            }),
        );
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <Step1
                        method={method}
                        onNextStep={(e) => setStep(e)}
                        setIsBlocking={setIsBlocking}
                        dataDetail={dataDetail}
                        form={form}
                        onReset={() => {
                            setfavoriteDispatch({});
                        }}
                    />
                );
            case 2:
                return (
                    <StepAnother
                        method={method}
                        onBackStep={() => setStep(1)}
                        setIsBlocking={setIsBlocking}
                        dataDetail={dataDetail}
                        form={form}
                        onReset={() => {
                            setfavoriteDispatch({});
                        }}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <RegisterDispatchProvider>
            <div className="register-dispatch mx-auto mt-[60px]">
                <div className="h-2 w-full bg-[#F7FAFD] my-8" />
                {renderStep()}
            </div>
        </RegisterDispatchProvider>
    );
}
