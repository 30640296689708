/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, Form, Input, Progress, Space } from "antd";
import { useWatch } from "antd/lib/form/Form";
import { Key, useEffect, useMemo, useState } from "react";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { get, isEmpty } from "lodash";
import { RouterKakao } from "@/utils/types/kakao";
import { KAKAO_REST_API_KEY, MIN_DISCOUNT_FREIGHT_COST, RouterType, vehicleTonnageOptions } from "@/utils/constants";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  combineDateTimeIncludeTimezone,
  formatPhoneNumber,
  swapElementsBetweenArrays,
  getRouterUrl,
  getMoneyRegardingDistance
} from "@/utils/commonFnc";
import {
  useCalcMoneyMutation,
  useChangeOrderStatusProviderMutation,
  useRegisterDispatchMutation,
  useUpdateDispatchMutation,
} from "../VehicleRegisApi";
import moment from "moment";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import numeral from "numeral";
import { openModal } from "@/components/customModal/modalSlice";
import { FormInstance } from "antd/es/form/Form";
import useUpdateEffect from "@/hooks/useUpdateEffect";
import { openDialog } from "@/components/customDialog/dialogSlice";
import cx from "classnames";
import { EOrderType } from "@/utils/types/order";
// import { useGetMyInfoQuery } from "@/pages/User/MyPageApi";
// import { getUserInfo } from "@/store/selector/RootSelector";
// import { LogEventNewMemberDispatch } from "@/utils/analytics";
import { useTranslation } from "react-i18next";
import repeatImg from "@/assets/images/repeat.png"

interface Props {
  onBackStep?: () => void;
  dataDetail?: any;
  form: FormInstance;
  onReset?: () => void;
  setIsBlocking?: (isBlocking: boolean) => void;
  method?: string | "one-way" | "two-way";
}
export default function StepAnother({ onBackStep, onReset, form, setIsBlocking, method, dataDetail }: Props) {
  const [loadingPlace, setLoadingPlace] = useState(false);
  const [duration, setDuration] = useState(0);
  const { t } = useTranslation();
  const startRouters = useWatch("addresses_step_2", form);
  const endRouters = useWatch("addresses_step_3", form);
  const isEditValue = useWatch("isEdit", form);
  const startDate = useWatch("startDate", form);
  const endDate = useWatch("endDate", form);
  const startTime = useWatch("startTime", form);
  const endTime = useWatch("endTime", form);
  // const userInfo = useSelector(getUserInfo);
  // const { data: myInfo } = useGetMyInfoQuery(
  //   { id: userInfo?.id },
  //   {
  //     refetchOnMountOrArgChange: true,
  //     skip: !userInfo?.id,
  //   },
  // );
  const isNormalMember = dataDetail?.providerPartnerId;
  const dispatch = useDispatch();
  const [registerDispatch, { isLoading }] = useRegisterDispatchMutation();
  const [updateDispatch, { isLoading: isLoadingUpdate }] = useUpdateDispatchMutation();
  const [calcMoney] = useCalcMoneyMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const idOrder = searchParams.get("id");
  const [changeOrderStatusProvider] = useChangeOrderStatusProviderMutation();

  const getDistance = async (starts: RouterKakao[], ends: RouterKakao[]) => {
    try {
      setLoadingPlace(true);
      let addressPoints: (string | undefined)[] = [];
      const waypoints: (string | undefined)[] = [];
      const waypointsStart = starts
        ?.filter((it: any) => it?.lotNumAddress || it?.fullAddress)
        .map((item, index) => {
          if (index !== 0) {
            return `${item.lng},${item.lat},name=${item.fullAddress}`;
          }
        })
        .filter(Boolean);
      const waypointEnds = ends
        ?.filter((it: any) => it?.lotNumAddress || it?.fullAddress)
        .map((item, index) => {
          if (index !== 0) {
            return `${item.lng},${item.lat},name=${item.fullAddress}`;
          }
        })
        .filter(Boolean);
      waypoints.push(...waypointsStart, ...waypointEnds);

      // if (method === "two-way") {
      //   const arr = [`${ends[0].lng},${ends[0].lat},name=${ends[0].fullAddress}`];
      //   waypoints.push(...waypointsStart, ...waypointEnds, ...arr);
      // }
      let params = "";
      waypoints.forEach((item, index) => {
        params += `waypoints=${item}${index < waypoints.length - 1 ? "&" : ""}`;
      });

      // address point
      const addressPointsStart = starts
        ?.filter((it: any) => it?.lotNumAddress || it?.fullAddress)
        .map((item, index) => {
          if (index !== 0) {
            return item?.lotNumAddress || item?.fullAddress;
          }
        })
        .filter(Boolean);
      const addressPointsEnds = ends
        ?.filter((it: any) => it?.lotNumAddress || it?.fullAddress)
        .map((item, index) => {
          if (index !== 0) {
            return item?.lotNumAddress || item?.fullAddress;
          }
        })
        .filter(Boolean);

      addressPoints.push(...addressPointsStart, ...addressPointsEnds);
      // if (method === "two-way") {
      //   const addressArr = [ends[0].fullAddress];
      //   addressPoints.push(...addressPointsStart, ...addressPointsEnds, ...addressArr);
      // }

      const payload =
      // method === "two-way"
      //   ? {
      //       origin: `${starts[0].lng},${starts[0].lat}`,
      //       destination: `${ends[0].lng},${ends[0].lat}`,
      //     }
      //   :
      {
        origin: `${starts[0].lng},${starts[0].lat}`,
        destination: `${ends[0].lng},${ends[0].lat}`,
        priority: RouterType.RECOMMEND,
        car_type: 3,
      };

      const response = await axios.get(`https://apis-navi.kakaomobility.com/v1/directions?${params}`, {
        headers: {
          Authorization: "KakaoAK " + KAKAO_REST_API_KEY,
          "Content-Type": "application/json",
        },
        params: payload,
      });
      const routes = get(response, "data.routes[0].summary", []);

      // NOTE: Tổng quãng đường
      const distance = routes?.distance || 0;

      const totalTime = method === "two-way" ? routes?.duration * 2 : routes?.duration;
      setDuration(totalTime);

      if (distance === 0) {
        const msgError = get(response, "data.routes[0].result_msg", "");
        dispatch(
          openDialog({
            type: "info",
            content:
              msgError === "service unavailable"
                ? "경로를 생성하기 어려운 주소 입니다. 다시 확인 해주세요"
                : msgError || "Unknow error",
          }),
        );
      }

      return {
        distance,
        // NOTE: Các chặng đường nếu có nhiều điểm
        childDistance:
          routes.length > 1
            ? routes.map((item: any) => ({
              distance: item.distance,
              // NOTE: Tính tiền cho từng quãng
              money: getMoneyRegardingDistance(item.distance),
            }))
            : [],
        additionalEndpoints: addressPoints,
      };
    } catch (err: any) {
      let msgError = err?.response?.data?.msg;
      dispatch(
        openDialog({
          type: "info",
          content:
            msgError === "service unavailable"
              ? "경로를 생성하기 어려운 주소 입니다. 다시 확인 해주세요"
              : msgError || "Unknow error",
        }),
      );
      return { distance: 0, childDistance: [] };
    } finally {
      setLoadingPlace(false);
    }
  };

  /* --------------------------------- 차 무게 표시 -------------------------------- */
  const mapVehicalWeight = (carType: (typeof vehicleTonnageOptions)[number]): string | undefined => {
    const mapping = {
      라보: "small",
      다마스: "small",
      "1톤": "1t",
      "1.4톤": "1_4t",
      "2.5톤": "2_5t",
      "3.5톤": "3_5t",
      "5톤": "5t",
      "5톤 축": "5Axist",
      "11톤": "11t",
      "11톤 축": "11Axist",
      "18톤": "18t",
      "25톤": "25t",
    };

    return mapping[carType];
  };

  const calculateDistance = async (starts: RouterKakao[], ends: RouterKakao[]) => {
    const startsWithProvince = starts.filter((item) => !!item.fullAddress);
    const endsWithProvince = ends.filter((item) => !!item.fullAddress);
    const { distance: totalDistance, childDistance, additionalEndpoints } = await getDistance(startRouters, endRouters);
    let startTime = form.getFieldValue("startTime");
    if (!!form.getFieldValue("isPickUpImmediately")) {
      const currentMinutes = moment().get("minutes");
      if (currentMinutes === 0) {
        startTime = moment().set("minutes", 30);
      } else if (currentMinutes <= 30) {
        startTime = moment().set("minutes", 30);
      } else {
        startTime = moment().set("minutes", 30).add(30, "minutes");
      }
    }
    let params = {
      vehicle: mapVehicalWeight(form.getFieldValue("tonnage")),
      km: totalDistance ? totalDistance / 1000 : 0,
      packingMethod: form.getFieldValue("info"),
      vehicleType: form.getFieldValue("vehicleType"),
      loadingPlace: form.getFieldValue("addresses_step_2")?.[0]?.lotNumAddress || form.getFieldValue("addresses_step_2")?.[0]?.fullAddress,
      receiptPlace: form.getFieldValue("addresses_step_3")?.[0]?.lotNumAddress || form.getFieldValue("addresses_step_3")?.[0]?.fullAddress,
      expectedStartDate:
        !!form.getFieldValue("startDate") && startTime
          ? combineDateTimeIncludeTimezone(
            moment(form.getFieldValue("startDate")).format("YYYY/MM/DD"),
            moment(startTime).format("HH:mm:ss"),
          )
          : null,
      additionalEndpoints,
    };

    let routerUrl = "";
    if (totalDistance > 0) {
      // if (method === "two-way") {
      //   routerUrl = getRouterUrlRoundTrip(startsWithProvince, endsWithProvince);
      // } else {
      routerUrl = getRouterUrl(startsWithProvince, endsWithProvince);
      // }
    }

    form.setFieldValue("routeUrl", routerUrl);
    if (method === "two-way") {
      form.setFieldValue("estimatedDistance", numeral((totalDistance * 2) / 1000).format("0,00.00"));
    } else {
      form.setFieldValue("estimatedDistance", numeral(totalDistance / 1000).format("0,00.00"));
    }

    const res = await calcMoney({
      ...params,
      isPickUpImmediately: !!form.getFieldValue("isPickUpImmediately"),
      orderType: method === "two-way" ? EOrderType.ROUND_TRIP : EOrderType.ONE_WAY,
      combineGoods: form.getFieldValue("isCombineGoods") === "registered" ? form.getFieldValue("combineGoods") : null,
    }).unwrap();

    const money = Math.max(res?.freightCost || 0, MIN_DISCOUNT_FREIGHT_COST);
    // const beMoney =
    form.setFieldValue("totalMoney", money);
    form.setFieldValue("freightCost", numeral(money).format("0,00"));
    if (money < 300000 && money > 0) {
      form.setFieldValue("fastShipping", numeral(money + 30000).format("0,00"));
    } else {
      form.setFieldValue("fastShipping", numeral(money + 50000).format("0,00"));
    }
    form.setFieldValue("isEdit", false);
  };

  useEffect(() => {
    if (!!location.search) {
      form.setFieldValue("isEdit", true);
    }
  }, [location.search]);

  useUpdateEffect(() => {
    if (!isEmpty(startRouters) && !isEmpty(endRouters) && isEditValue) {
      let startRoutersNew = form.getFieldValue("addresses_step_2")?.filter((it: any) => it?.fullAddress);
      let endRoutersNew = form.getFieldValue("addresses_step_3")?.filter((it: any) => it?.fullAddress);

      if (startRoutersNew?.length > 0 && endRoutersNew?.length > 0) {
        calculateDistance(startRoutersNew, endRoutersNew);
      }
    }
  }, [startRouters, endRouters, isEditValue]);

  const isRouteFilled = useMemo(() => {
    const isStartFilled = startRouters?.every((route: { fullAddress: any; detailAddress: any; }) => route?.fullAddress && route?.detailAddress);
    const isEndFilled = endRouters?.every((route: { fullAddress: any; detailAddress: any; }) => route?.fullAddress && route?.detailAddress);
    if (isStartFilled && isEndFilled) {
      return true;
    }
    return false;
  }, [JSON.stringify(startRouters), JSON.stringify(endRouters)]);

  const handleResetForm = (step: number) => () => {
    const step2 = [
      "addresses_step_2",
      "contactSender",
      "startDate",
      "startTime",
      "estimatedDistance",
      "freightCost",
      "fastShipping",
    ];
    const step3 = [
      "addresses_step_3",
      "contactReceiver",
      "endDate",
      "endTime",
      "estimatedDistance",
      "freightCost",
      "fastShipping",
    ];

    form.resetFields(step === 2 ? step2 : step3);
    onReset?.();
  };

  const handleSwapRouters = () => {
    const listAddressLoading = startRouters;
    const listAddressUnloading = endRouters;
    let [newField1, newField2] = swapElementsBetweenArrays(listAddressLoading, listAddressUnloading, 0);
    form.setFieldValue("addresses_step_2", newField1);
    form.setFieldValue("addresses_step_3", newField2);

    setTimeout(() => {
      form.setFieldValue("isEdit", true);
    }, 500);
  };

  const getDuration = (startTime: any, endTime: any) => {
    let seconds = endTime.diff(startTime, "seconds");
    if (seconds) {
      return seconds;
    }
    return 0;
  };

  const transformData = (data: { fax: any; }) => {
    if ("fax" in data && !data.fax) {
      delete data.fax;
    }
    return data;
  };

  const checkDateRangeValid = (startDate: moment.MomentInput, endDate: moment.MomentInput, startTime = null, endTime = null) => {
    let isValid = true;
    if (startDate && endDate && moment(startDate).isValid() && moment(endDate).isValid()) {
      if (startTime && endTime) {
        const expectedStartDateTime = moment(
          moment(startDate).format("YYYY/MM/DD") + " " + moment(startTime).format("HH:mm:ss"),
        );
        const expectedEndDateTime = moment(
          moment(endDate).format("YYYY/MM/DD") + " " + moment(endTime).format("HH:mm:ss"),
        );
        const isCreateDispatch = !idOrder;
        if (
          expectedEndDateTime < expectedStartDateTime ||
          expectedEndDateTime < moment() ||
          (isCreateDispatch && expectedStartDateTime < moment()) ||
          (!isCreateDispatch &&
            dataDetail?.expectedStartDate !== expectedStartDateTime &&
            expectedStartDateTime < moment())
        ) {
          isValid = false;
        }
      }
      if (moment(endDate).startOf("day") < moment(startDate).startOf("day")) {
        isValid = false;
      }
    }
    return isValid;
  };

  // useEffect(() => {
  //   if (!checkDateRangeValid(startDate, endDate, startTime, endTime)) {
  //     dispatch(
  //       openDialog({
  //         type: "info",
  //         content: "상/하차 예정 일시가 올바르지 않습니다. <br/>확인해주세요.",
  //       }),
  //     );
  //   }
  // }, [startDate, endDate, startTime, endTime]);

  const handleFinish = async (values: any) => {
    if (values.isPickUpImmediately) {
      const currentMinutes = moment().get("minutes");
      if (currentMinutes === 0) {
        values.startTime = moment().set("minutes", 30);
      } else if (currentMinutes <= 30) {
        values.startTime = moment().set("minutes", 30);
      } else {
        values.startTime = moment().set("minutes", 30).add(30, "minutes");
      }
    }

    if (values.isDropDownImmediately) {
      if (values.isPickUpImmediately) {
        values.endTime = moment(values.startTime).add(30, "minutes");
      } else {
        let now = moment();
        const currentMinutes = moment().get("minutes");
        if (currentMinutes === 0) {
          now = moment().set("minutes", 30);
        } else if (currentMinutes <= 30) {
          now = moment().set("minutes", 30);
        } else {
          now = moment().set("minutes", 30).add(30, "minutes");
        }
        values.endTime = moment(now > values.startTime ? now : values.startTime).add(30, "minutes");
      }
    }

    if (!checkDateRangeValid(startDate, endDate, values.startTime, values.endTime)) {
      dispatch(
        openDialog({
          type: "info",
          content: "상/하차 예정 일시가 올바르지 않습니다.",
        }),
      );
      return;
    }

    const uploadData: any = {
      type: form.getFieldValue("type"),
      info: form.getFieldValue("info"),
      tonnage: form.getFieldValue("tonnage"),
      quantity: parseInt(form.getFieldValue("quantity")),
      vehicleType: form.getFieldValue("vehicleType"),
      packingMethod: form.getFieldValue("packingMethod"),
      requirement: form.getFieldValue("requirement"),
      receiptPlace: get(values, "addresses_step_3[0].fullAddress", ""),
      detailReceiptPlace: get(values, "addresses_step_3[0].detailAddress", ""),
      loadingPlace: values?.addresses_step_2[0].fullAddress,
      detailLoadingPlace: get(values, "addresses_step_2[0].detailAddress"),
      fax: form.getFieldValue("fax")?.trim().replaceAll("-", ""),
      loadingPlaceCoordX: values?.addresses_step_2[0].coorX.toString(),
      loadingPlaceCoordY: values?.addresses_step_2[0].coorY.toString(),
      receiptPlaceCoordX: values?.addresses_step_3[0].coorX.toString(),
      receiptPlaceCoordY: values?.addresses_step_3[0].coorY.toString(),
      loadingPlaceX: values?.addresses_step_2[0].lng,
      loadingPlaceY: values?.addresses_step_2[0].lat,
      receiptPlaceX: values?.addresses_step_3[0].lng,
      receiptPlaceY: values?.addresses_step_3[0].lat,
      loadingLotNumAddress: values?.addresses_step_2[0].lotNumAddress,
      receiptLotNumAddress: values?.addresses_step_3[0].lotNumAddress,
      transitPlaces: values?.addresses_step_2.filter((_: any, index: number) => index > 0),
      additionalEndpoints: values?.addresses_step_3.filter((_: any, index: number) => index > 0),
      docUrls: form.getFieldValue("docUrls")?.map((item: any) => ({
        name: item?.name,
        url: item?.response?.Location || item?.url || item?.file,
      })),
      routeUrl: values.routeUrl,
      estimatedDistance: numeral(values.estimatedDistance).value(),
      estimatedTravelTime:
        duration ||
        getDuration(
          moment(moment(values.startDate).format("YYYY/MM/DD") + " " + moment(values.startTime).format("HH:mm:ss")),
          moment(moment(values.endDate).format("YYYY/MM/DD") + " " + moment(values.endTime).format("HH:mm:ss")),
        ) ||
        0,
      expectedStartDate: moment(
        moment(values.startDate).format("YYYY/MM/DD") + " " + moment(values.startTime).format("HH:mm:ss"),
      ).format(),
      expectedEndDate: moment(
        moment(values.endDate).format("YYYY/MM/DD") + " " + moment(values.endTime).format("HH:mm:ss"),
      ).format(),
      freightCost: values.isFastShipping ? numeral(values.fastShipping).value() : numeral(values.freightCost).value(),
      isFastShipping: values?.isFastShipping,
      contactSender: values?.contactSender?.replaceAll("-", ""),
      contactReceiver: values?.contactReceiver?.replaceAll("-", ""),
      isCombineGoods: form.getFieldValue("isCombineGoods") === "registered",
      combineGoods: form.getFieldValue("combineGoods"),
      isPickUpImmediately: values?.isPickUpImmediately,
      isDropDownImmediately: values?.isDropDownImmediately,
      orderType: method === "two-way" ? "ROUND_TRIP" : "ONE_WAY",
      isCash: values?.isCash
    };

    if (form.getFieldValue("providerPartnerId") && !isNormalMember) {
      uploadData["providerPartnerId"] =
        form.getFieldValue("providerPartnerId") === "해당 없음" ? null : form.getFieldValue("providerPartnerId");
    }
    // transform before store or update
    transformData(uploadData);

    try {
      const makingOrderRes = await updateDispatch({ id: idOrder, data: uploadData }).unwrap();

      if (makingOrderRes?.error) {
        throw new Error(makingOrderRes?.error?.data?.message);
      }

      // setIsBlocking?.(false);

      dispatch(
        openDialog({
          type: "info",
          content: "배차내역이 수정되었습니다.",
          actionConfirm: () => {
            if (makingOrderRes?.id) {
              navigate("/dispatch-management?id=" + makingOrderRes.id);
            }
          },
        }),
      );
    } catch (error: any) {
      dispatch(
        openDialog({
          type: "info",
          content: error?.data?.message || "알 수 없는 문제",
        }),
      );
    }
  };

  const handleStartRouter = () => {
    dispatch(
      openModal({
        template: "favorite-router",
        width: 555,
        actionConfirm: (selectedVehicle: any) => {
          /* --------------------------------- 상차지 나열 --------------------------------- */
          const startRoutersDefault: RouterKakao[] = [
            {
              coorX: selectedVehicle?.loadingPlaceCoordX,
              coorY: selectedVehicle?.loadingPlaceCoordY,
              lat: selectedVehicle?.loadingPlaceX,
              lng: selectedVehicle?.loadingPlaceY,
              fullAddress: selectedVehicle?.loadingPlace,
              detailAddress: selectedVehicle?.detailLoadingPlace,
            },
            ...selectedVehicle?.transitPlaces,
          ];

          form.setFieldValue("contactSender", selectedVehicle.contactSender);
          form.setFieldValue("addresses_step_2", startRoutersDefault);
          setTimeout(() => {
            form.setFieldValue("isEdit", true);
          }, 500);
        },
        data: {
          method,
          isFavourite: false,
          startRouter: true,
        },
      }),
    );
  };

  const handleEndRouter = () => {
    dispatch(
      openModal({
        template: "favorite-router",
        width: 555,
        actionConfirm: (selectedVehicle: any) => {
          /* --------------------------------- 하차지 나열 --------------------------------- */
          const endRoutersDefault: RouterKakao[] = [
            {
              coorX: selectedVehicle?.receiptPlaceCoordX,
              coorY: selectedVehicle?.receiptPlaceCoordY,
              lat: selectedVehicle?.receiptPlaceX,
              lng: selectedVehicle?.receiptPlaceY,
              fullAddress: selectedVehicle?.receiptPlace,
              detailAddress: selectedVehicle?.detailReceiptPlace,
            },
            ...selectedVehicle?.additionalEndpoints,
          ];

          form.setFieldValue("contactReceiver", formatPhoneNumber(selectedVehicle.contactReceiver));
          form.setFieldValue("addresses_step_3", endRoutersDefault);
          setTimeout(() => {
            form.setFieldValue("isEdit", true);
          }, 500);
        },
        data: {
          method,
          isFavourite: false,
          startRouter: false,
        },
      }),
    );
  };

  const handleStartPartnerRouter = () => {
    dispatch(
      openModal({
        template: "partner-favorite-router",
        width: 670,
        actionConfirm: (selectedVehicle: any) => {
          /* --------------------------------- 상차지 나열 --------------------------------- */
          const startRoutersDefault: RouterKakao[] = [
            {
              coorX: selectedVehicle?.addressCoordX,
              coorY: selectedVehicle?.addressCoordY,
              lat: selectedVehicle?.addressX,
              lng: selectedVehicle?.addressY,
              fullAddress: selectedVehicle?.address,
              detailAddress: selectedVehicle?.detailAddress,
            },
          ];

          form.setFieldValue("contactSender", selectedVehicle.phone);
          form.setFieldValue("addresses_step_2", startRoutersDefault);
          setTimeout(() => {
            form.setFieldValue("isEdit", true);
          }, 500);
        },
        data: {
          method,
        },
      }),
    );
  };

  const handleEndPartnerRouter = () => {
    dispatch(
      openModal({
        template: "partner-favorite-router",
        width: 670,
        actionConfirm: (selectedVehicle: any) => {
          /* --------------------------------- 하차지 나열 --------------------------------- */
          const endRoutersDefault: RouterKakao[] = [
            {
              coorX: selectedVehicle?.addressCoordX,
              coorY: selectedVehicle?.addressCoordY,
              lat: selectedVehicle?.addressX,
              lng: selectedVehicle?.addressY,
              fullAddress: selectedVehicle?.address,
              detailAddress: selectedVehicle?.detailAddress,
            },
          ];

          form.setFieldValue("contactReceiver", formatPhoneNumber(selectedVehicle.phone));
          form.setFieldValue("addresses_step_3", endRoutersDefault);
          setTimeout(() => {
            form.setFieldValue("isEdit", true);
          }, 500);
        },
        data: {
          method,
        },
      }),
    );
  };

  return (
    <div className=" px-[222px]  w-[1440px] mx-auto step-first-dispatch">
      {/* <div className="flex items-center justify-between">
        <Space size={16}>
          <span className="text-2xl font-bold text-primary-brandblue capitalize">STEP 2</span>
          <span className="text-[#131416] font-bold text-2xl">상차지 정보</span>
          <Button className="rounded-lg h-[38px] px-5" onClick={handleResetForm(2)}>
            초기화
          </Button>
          <Button
            className="rounded-lg h-[38px] px-5"
            onClick={() => {
              isNormalMember ? handleStartRouter() : handleStartPartnerRouter();
            }}
          >
            주소록
          </Button>
        </Space>
        <Space size={16}>
          <span className="text-2xl font-bold text-primary-brandblue capitalize">STEP 3</span>
          <span className="text-[#131416] font-bold text-2xl">하차지 정보</span>
          <Button className="rounded-lg h-[38px] px-5" onClick={handleResetForm(3)}>
            초기화
          </Button>
          <Button
            className="rounded-lg h-[38px] px-5"
            onClick={() => {
              isNormalMember ? handleEndRouter() : handleEndPartnerRouter();
            }}
          >
            주소록
          </Button>
        </Space>
      </div> */}
      {/* <div className="my-6">
        <Progress percent={75} strokeWidth={2} strokeColor="#5d98d1" showInfo={false} />
      </div> */}
      <Form
        onFinish={handleFinish}
        form={form}
        labelAlign="left"
        labelCol={{
          span: 4,
          offset: 0,
        }}
        wrapperCol={{
          span: 16,
          offset: 2,
        }}
      >
        <Form.Item valuePropName="checked" name="isEdit" className="hidden" style={{ visibility: 'hidden' }}>
          <Checkbox />
        </Form.Item>
        <div className={cx("", method === "two-way" ? "flex gap-[22px]" : "grid grid-cols-12 gap-6")}>
          <div
            className={cx(
              "",
              method === "two-way" ? "p-5 bg-[#F7FAFD] col-span-6  flex-1 rounded-[20px] " : "col-span-5",
            )}
          >
            <Step2 form={form} loading={loadingPlace} method={method} />
          </div>
          <div className={cx("", method === "two-way" ? "w-[72px] flex justify-center items-start" : "col-span-2")}>
            <Button
              className={cx(
                "",
                method === "two-way"
                  ? "flex flex-col gap-1  items-center justify-center rounded-lg h-[66px] w-[72px] bg-[#F7FAFD] mt-[60px]"
                  : "flex items-center justify-center rounded-lg h-[50px] bg-[#F7FAFD] space-x-2 mt-[60px]",
              )}
              onClick={handleSwapRouters}
            >
              <img
                src={repeatImg}
                alt="repeat"
                width={20}
                height={20}
                className="w-6 h-6 p-px rounded-[50%] border-2 border-solid border-[#DFE0E2]"
              />
              <span className="font-bold text-sm text-primary-blue">주소 변경</span>
            </Button>
          </div>
          <div
            className={cx(
              "",
              method === "two-way" ? "p-5 bg-[#F7FAFD] flex-1 col-span-6 rounded-[20px]" : "col-span-5",
            )}
          >
            <Step3 form={form} loading={loadingPlace} method={method} />
          </div>
        </div>
        <div className="flex flex-col">
          {method === "two-way" && (
            <>
              <span className="pt-8 pb-4 text-sm">
                <span className="text-[#E21B1B]">*</span>아래의 왕복 경로 주소는 상단 입력한 주소로 자동으로 설정됩니다.
              </span>

              <div className="flex justify-between">
                <div className="w-1/2 justify-start">
                  {endRouters?.map((route: { fullAddress: string; detailAddress: string }, index: number) => {
                    const isLastItem = index === startRouters.length - 1;
                    return (
                      <div
                        className={cx("flex p-5 w-[440px] justify-between bg-[#F7FAFD] rounded-[20px] mb-4", {
                          "mb-4": !isLastItem,
                        })}
                      >
                        <span className="title min-w-[91px] text-base">상차지</span>
                        <div className="flex flex-col gap-3">
                          <Input value={route?.fullAddress} className="w-[300px] h-[50px] rounded-lg" disabled />
                          <Input value={route?.detailAddress} className="w-[300px] h-[50px] rounded-lg" disabled />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="w-1/2  flex flex-col justify-start items-end">
                  {startRouters?.map((route: { fullAddress: string; detailAddress: string }, index: Key | null | undefined) => {
                    const isLastItem = index === startRouters.length - 1;
                    return (
                      <div
                        className={cx("flex p-5 w-[440px] justify-between bg-[#F7FAFD] rounded-[20px] mb-4", {
                          "mb-4": !isLastItem,
                        })}
                        key={index}
                      >
                        <span className="title min-w-[91px] text-base">하차지</span>
                        <div className="flex flex-col gap-3">
                          <Input value={route?.fullAddress} className="w-[300px] h-[50px] rounded-lg" disabled />
                          <Input value={route?.detailAddress} className="w-[300px] h-[50px] rounded-lg" disabled />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
        <Step4
          method={method}
          form={form}
          onBack={onBackStep}
          loading={!!idOrder ? isLoadingUpdate : isLoading}
          loadingPlace={loadingPlace}
        />
      </Form>
    </div>
  );
}
